<template>
  <div class="page">
    <h2 class="main-title">{{title}}</h2>
    <SubMenu/>
    <router-view/>
  </div>
</template>

<script>
  import SubMenu from '@/components/SubMenu/SubMenu.vue'
  import {mapMutations} from 'vuex'

  export default {
    name: 'Adminpanel',
    components: {
      SubMenu
    },
    data () {
      return {
        title: 'Администрирование',
      }
    },
    methods: {
      ...mapMutations({
        menuMutations: 'login/menuMutations'
      }),
    },
    mounted() {
      this.menuMutations(this.$route.matched[0].name)
    }
  }
</script>
